import React, { Component } from 'react';
import styles from './App.module.css';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <Router>
        <div className={styles.App}>
          <Header />
          <div className={styles.AppContainer}>
          <Switch>
            <Route exact path="/"  component={Dashboard} />
            <Route path="/:kvkNummer" component={Dashboard} />
          </Switch>
          </div>
          {/* <div className={styles.bottombar}></div> */}
        </div>
      </Router>
    );
  }
}

export default App;
