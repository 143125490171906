import React from 'react';
// import logo from '../images/logo.svg';
import dop from '../images/dop.png';
import styles from './Header.module.css';

const Header = (props) => {
  return (
    <div>
      <img style={{width:'100%'}} className={styles.dop} src={dop} alt="dop" />
      {/* <div className={styles.topbar}></div>
      <div className={styles.main}>
        <div className={styles.left}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.title}>Wetten en Regels</div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
