import React, { Component } from 'react';
import styles from './SearchAutocomplete.module.css';
import { Input } from 'reactstrap';
import magnifyingGlass from '../images/magnifying-glass.svg';
import cross from '../images/cross.svg';
import axios from 'axios';
import { debounce } from 'lodash';

class SearchAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.onChangeDebounce = debounce(this.onChangeDebounce, 500);
  }

  state = {
    searchValue: '',
    searchValueQuick: '',
    items: [],
    showPopup: false,
    selectedIndex: 0,
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!this.state.items.length) return;
      let selectedItem = this.state.items[this.state.selectedIndex];
      this.itemSelected(selectedItem);
    } else if (e.key === 'ArrowDown') {
      let selectedIndex = this.state.selectedIndex;
      let items = this.state.items;
      if (selectedIndex === items.length - 1) return;
      this.setState({
        selectedIndex: selectedIndex + 1,
      });
    } else if (e.key === 'ArrowUp') {
      let selectedIndex = this.state.selectedIndex;
      if (selectedIndex === 0) return;
      this.setState({
        selectedIndex: selectedIndex - 1,
      });
    } else if (e.key === 'Escape') {
      this.setState({ searchValue: '', searchValueQuick: '' });
    }
  };

  onChange = (event) => {
    let searchValue = event.target.value;
    this.setState({
      searchValueQuick: searchValue,
    });
    this.onChangeDebounce(searchValue);
  };

  onChangeDebounce = (searchValue) => {
    axios
      .get(`/api/search?tradeName=${encodeURIComponent(searchValue)}`)
      .then(({ data }) => {
        this.setState({
          searchValue,
          showPopup: true,
          items: data,
          selectedIndex: 0,
        });
      })
      .catch((error) => {
        this.setState({ data: null, error: 'niks gevonden' });
        console.log(error.message);
      });
  };

  onBlur = (e) => {
    setTimeout(() => {
      this.setState({
        showPopup: false,
      });
    }, 300);
  };

  itemSelected = (item) => {
    // console.log(item);
    this.props.history.push(`/${item.kvkNummer}`);
    this.setState({
      showPopup: false,
      searchValue: '',
      searchValueQuick: '',
    });
  };

  renderLabel(label, searchValue) {
    let ind = label.toLowerCase().indexOf(searchValue.toLowerCase());
    if (ind === -1) {
      return <div>{label}</div>;
    }
    let end = ind + searchValue.length;
    let l1 = label.slice(0, ind);
    let l2 = label.slice(ind, end);
    let l3 = label.slice(end);
    return (
      <div>
        <span>{l1}</span>
        <span style={{ fontWeight: 'bold' }}>{l2}</span>
        <span>{l3}</span>
      </div>
    );
  }

  renderItems() {
    let { items, showPopup, searchValue, selectedIndex } = this.state;
    if (!showPopup) return null;
    if (!items.length) return <div className={styles.noResults}>geen resultaten gevonden</div>;

    return (
      <div className={styles.items}>
        {items.map((item, index) => {
          let name = item.handelsnaam;
          return (
            <div
              key={index}
              className={styles.item + ' ' + (selectedIndex === index ? styles.selected : '')}
              onClick={() => this.itemSelected(item)}>
              {this.renderLabel(name, searchValue)}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.main} style={{ alignItems: 'stretch' }}>
        <div className={styles.inputContainer}>
          <Input
            value={this.state.searchValueQuick}
            component="input"
            type="text"
            onKeyDown={this.handleKeyPress}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
          {this.state.searchValue ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ searchValue: '', searchValueQuick: '' })}
              src={cross}
              alt="empty"
            />
          ) : (
            <img src={magnifyingGlass} alt="magnifying glass" />
          )}
        </div>
        {this.renderItems()}
      </div>
    );
  }
}

export default SearchAutocomplete;
